<template>
  <div class="content">
    <div class="bgtop">
      <div class="topLeft">
        <img class="goUp"
             @click="backAnswer"
             src="@/assets/interesing/退出@2x.png"
             alt="">
        <span>单词记忆</span>
      </div>
      <div class="topRight">
        <img src="@/assets/interesing/Left@2x (1).png"
             alt=""
             @click="back()">
        <img src="@/assets/interesing/Right@2x.png"
             alt=""
             @click="nextNum()">
        <img src="@/assets/interesing/答题卡@2x.png"
             alt=""
             @click="todetail()">
      </div>
    </div>
    <div class="topic"
         v-for="(item) in topicListData"
         :key="item.question_id"
         v-show="item.num == idx">
      <!-- <div class="tips">题目：{{item.question_id}}</div> -->
      <!-- <div class="score">{{item.question_type_name}}：{{item.question_score}}分，{{item.source_name}}</div> -->
      <div class="answer">
        <div>
          {{item.question}}
        </div>
        <div class="score"
             v-show="item.question == item.translation ? false :true">{{item.translation}}</div>
        <div class="score"
             v-show="item.type == 1">{{item.phonetic}}</div>
      </div>
      <div class="reply">
        <div class="reprybg">
          <div class="option"
               v-for="(itemopt,index) in item.question_option"
               :key="index"
               :style="{ backgroundColor: index === selectedOptionIndex ? isActive == item.answer?'#E3F2FD':'#FFEBEE': 'white'}"
               @click="optionClick(itemopt,idx,item,index)">{{itemopt}}</div>
        </div>
      </div>
    </div>
    <!-- <Form ref="FormRef" />
    <FeedBack ref="FeedBackRef" /> -->
  </div>
</template>
<script>
import { getWordList, subWord } from '@/api/Aaswer.js'
import Form from '@/views/interesting/components/form.vue'
import FeedBack from '@/views/interesting/components/feedback.vue'
export default {
  data () {
    return {
      topicListData: {},
      idx: 1,
      question_content: '',
      rightNum: 0,
      errorNum: 0,
      group_id: '',
      subjectId: '',
      isActive: '',
      indexFlag: '',
      choiceResultData: '',
      itemoption: [],
      startTime: '',
      endTime: '',
      selectedOptionIndex: null

    }
  },
  components: {
    Form,
    FeedBack
  },
  async created () {
    let userinfo = JSON.parse(window.localStorage.getItem('userInfo'))
    let userId = Number(userinfo.user_id)
    // let subjectId = Number(this.$route.query.subject_id)
    const topicList = await getWordList({
      uid: userId,
      // subject_id: subjectId
    })
    console.log('topicList下午', topicList)
    var index = 1
    topicList.data.forEach((item) => {
      item.num = index++
    })
    this.topicListData = topicList.data
    this.topicListData.forEach(item => item.question_option = Array.from(new Set(item.question_option)))
    this.group_id = topicList.data[0].group_id
  },
  methods: {
    feed () {
      let form = {
        category: 0,
        comment: '',
        question_id: this.topicListData[this.idx - 1].question_id,
      }
      this.$refs.FeedBackRef.form = form
      this.$refs.FeedBackRef.dialogVisible = true
    },
    showInsDialog () {
      let form = {
        board_id: '',
        discuss_title: '',
        discuss_content: '',
        question_id: this.topicListData[this.idx - 1].question_id,
      }
      this.$refs.FormRef.dialogVisible = true
      this.$refs.FormRef.form = form
    },
    resfresh () {
      this.$router.go(0)
    },
    back () {
      console.log('idx', this.idx)
      if (this.idx > 1) {
        this.idx--
      }
    },
    async next () {
      let userinfo = JSON.parse(window.localStorage.getItem('userInfo'))
      let userId = Number(userinfo.user_id)
      await subWord({
        group_id: Number(this.itemoption.group_id),
        question_id: Number(this.itemoption.question_id),
        question: this.itemoption.question,
        option: this.choiceResultData,
        uid: userId,
        use_time: Math.floor((this.endTime - this.startTime) / 1000) < 0 ? -Math.floor((this.endTime - this.startTime) / 1000) : Math.floor((this.endTime - this.startTime) / 1000)
      }).then((res) => {
        if (this.idx < this.topicListData.length) {
          // 在开始做题时记录开始时间
          this.startTime = new Date().getTime();
          // this.isActive = ''
          this.idx++
          this.selectedOptionIndex = ''
        } else {
          this.todetail()

        }
      })

    },
    nextNum () {
      if (this.idx < this.topicListData.length) {

        // this.isActive = ''
        this.idx++
        this.selectedOptionIndex = ''
      } else {
        this.todetail()

      }
    },
    backAnswer () {
      this.$router.push({
        path: '/home',
      })
    },
    todetail () {
      // console.log('789465', this.topicListData)
      this.$router.push({
        path: '/wordDetail',
        query: {
          group_id: this.group_id,
          subjectId: this.subjectId,
          rightNum: this.rightNum,
          errorNum: this.errorNum,
          topicListData: this.topicListData
        }
      })
    },
    // 获取存储的答案
    optionClick (option, idx, itemoption, index) {
      if (this.startTime == '') {
        this.startTime = new Date().getTime();
      }
      this.endTime = new Date().getTime()
      // console.log('itemoption', itemoption)
      const item = this.topicListData[idx - 1];
      this.indexFlag = this.topicListData[idx - 1]
      console.log('index', index)
      this.selectedOptionIndex = index;
      // console.log('option', option)

      this.itemoption = itemoption
      this.isActive = option
      console.log('itemoption', this.itemoption)
      this.choiceResultData = option
      item.choiceResult = option;

      if (item.answer == option) {
        item.isTrue = true;
        this.rightNum++;

      } else {
        item.isTrue = false;
        this.errorNum++;
        // this.$message.error('回答错误')
      }
      this.next()
    },
  }
}
</script>
<style lang="scss" scoped>
.content {
  cursor: pointer;
  .bgtop {
    width: 100%;
    height: 100rem;
    padding: 26rem 40rem;
    box-sizing: border-box;
    background: #2196f3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .topLeft {
      display: flex;
      justify-content: center;
      align-items: center;

      .goUp {
        width: 48rem;
        height: 48rem;
        margin-right: 24rem;
      }
      .data {
        width: 32rem;
        height: 32rem;
        margin-left: 24rem;
      }
      span {
        font-size: 28rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 42rem;
      }
    }
    .topRight {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 48rem;
        height: 48rem;
        margin-left: 24rem;
      }
      span {
        font-size: 28rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 42rem;
        margin-left: 24rem;
      }
    }
  }
  .topic {
    padding: 0rem 40rem 0rem 40rem;
    box-sizing: border-box;
    .tips {
      font-size: 28rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 42rem;
      margin-bottom: 8rem;
    }
    .score {
      font-size: 24rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 36rem;
      // margin-bottom: 32rem;
    }
    .answer {
      font-size: 32rem;
      height: 600rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-bottom: 32rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 24rem;
    }
    .check {
      height: 240rem;
      margin-bottom: 32rem;
    }
    .reply {
      display: flex;
      height: 152rem;
      justify-content: space-between;
      color: #333333;
      background-color: #f5f5f5;
      padding: 8rem;
      box-sizing: border-box;
      .reprybg {
        width: 100%;
        background-color: #ffffff;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .option {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          border: 1px solid #f5f5f5;
          align-items: center;
          font-size: 32rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}
</style>